import { Badge, Col, Row, Typography } from 'antd'
import { CSSProperties, FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { withPrefix } from '../../contexts/Config'
import { Button } from '../form'
import { TextLink } from '../text'
import { ClickableText } from '../text/ClickableText'
import { Flex, HorizontalSpace, VerticalSpace } from './Grid'
import './Section.less'

export type SectionProps = {
  title?: string | ReactNode
  subTitle?: string | ReactNode
  titleSize?: 1 | 2 | 3 | 5 | 4
  titleType?: 'default' | 'secondary'
  link?: string
  text?: string
  size?: number
  className?: string
  style?: CSSProperties
  actions?: ReactNode
  badgeCount?: number
  onHandleTextClick?: () => void
}

function SectionTitle({ title, titleSize, titleType }) {
  return title ? (
    typeof title === 'string' ? (
      <Typography.Title
        className={withPrefix('section-title')}
        level={titleSize}
        type={titleType === 'secondary' ? 'secondary' : undefined}
        style={{ marginBottom: 0 }}
      >
        {title}
      </Typography.Title>
    ) : (
      title
    )
  ) : null
}

export const Section: FC<SectionProps> = ({
  title,
  subTitle,
  titleType = 'secondary',
  text = 'See all',
  link,
  titleSize = 3,
  size = 8,
  style = {},
  onHandleTextClick,
  className,
  badgeCount,
  actions,
  children,
}) => (
  <Flex
    direction={'vertical'}
    className={withPrefix('section', className ? className : '')}
    style={{ width: '100%', ...style }}
    size={size}
  >
    <Row justify={'space-between'} align={'middle'}>
      <Col>
        {title &&
          (badgeCount ? (
            <HorizontalSpace className={withPrefix('section-title-wrapper')} align={'center'}>
              <SectionTitle title={title} titleSize={titleSize} titleType={titleType} />
              <Badge count={badgeCount} />
            </HorizontalSpace>
          ) : (
            <SectionTitle title={title} titleSize={titleSize} titleType={titleType} />
          ))}
        {subTitle && typeof subTitle === 'string' ? (
          <Typography.Text type={'secondary'}>{subTitle}</Typography.Text>
        ) : (
          subTitle
        )}
      </Col>

      {link && link !== '' ? (
        <Col>
          <Link
            to={link}
            type={'success'}
            onClick={onHandleTextClick}
            style={{
              textDecoration: 'underline',
              fontWeight: 'bold',
            }}
          >
            {text}
          </Link>
        </Col>
      ) : (
        onHandleTextClick && (
          <Col>
            <ClickableText text={text} onClick={onHandleTextClick} />
          </Col>
        )
      )}
    </Row>
    {children}
  </Flex>
)
